import AboutPage from '@pretto/bricks/website/shared/pages/AboutPage'

import { EnSavoirPlus as EnSavoirPlusView } from '@pretto/website/src/templates/enSavoirPlus/views/EnSavoirPlus/EnSavoirPlus'

import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

export const EnSavoirPlus = ({ data, ...props }) => {
  const headerComponent = useMemo(() => <AboutPage steps={data.template.about_deprecated.steps} />, [])

  return <EnSavoirPlusView {...props} headerComponent={headerComponent} title="Domptez votre projet immobilier" />
}

EnSavoirPlus.propTypes = {
  data: PropTypes.shape({
    template: PropTypes.shape({
      about_deprecated: PropTypes.shape({
        steps: PropTypes.array.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

export const fragment = graphql`
  fragment EnSavoirPlusTemplate on WpTemplate_EnSavoirPlusdprci {
    about_deprecated {
      steps {
        end {
          content
          image
          title
        }
        experts {
          content
          image
          title
        }
        folder {
          content
          image
          title
        }
        loan {
          content
          image
          title
        }
        negotiation {
          content
          image
          title
        }
        rate {
          content
          image
          title
        }
      }
    }
  }
`
