import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import * as typo from '@pretto/bricks/core/typography'

import styled from 'styled-components'

export const Content = styled.div`
  ${column([2, 4])};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([4, 8])};
  }
`

export const Page = styled.div`
  ${grid()};
  margin-bottom: ${g(4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(9)};
  }
`

export const Title = styled.h1`
  ${typo.heading48}
  margin-bottom: ${g(4)};
  text-align: center;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(6)};
  }
`
