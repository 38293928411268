import { PostContentContext } from '@pretto/website/src/postTypes/context/PostContent'

import PropTypes from 'prop-types'
import { useContext } from 'react'

import * as S from './EnSavoirPlus.styles'

export const EnSavoirPlus = ({ headerComponent, title }) => {
  const { contentComponent } = useContext(PostContentContext)

  return (
    <S.Page>
      <S.Content>
        <S.Title>{title}</S.Title>

        {headerComponent}
        {contentComponent}
      </S.Content>
    </S.Page>
  )
}

EnSavoirPlus.propTypes = {
  headerComponent: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
}
