import { breakpoints, g, ng } from '@pretto/bricks/components/layout'
import BaseImage from '@pretto/bricks/website/shared/components/Image'

import styled from 'styled-components'

export const Steps = styled.section``

export const StepSeparation = styled.div`
  display: flex;
  position: absolute;
  top: ${g(1 / 2)};
  bottom: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: ${breakpoints.tablet}) {
    left: calc(50% - ${g(1)});
  }
`

export const StepSeparationDot = styled.div`
  flex: 0 0 ${g(2)};
  margin-bottom: ${g(1)};
  border-radius: 50%;
  width: ${g(2)};
  height: ${g(2)};

  background-color: ${({ field, theme }) => {
    switch (field) {
      case 'rate':
        return theme.legacy.colors.accent1.default

      case 'experts':
        return theme.legacy.colors.neutral4.default

      case 'folder':
        return theme.legacy.colors.accent1.default

      default:
        return theme.legacy.colors.primary1.default
    }
  }};
`

export const StepSeparationLine = styled.div`
  flex: 1 1 100%;
  width: 2px;

  background: ${({ field, theme }) => {
    switch (field) {
      case 'loan':
        return `linear-gradient(${theme.legacy.colors.primary1.default}, ${theme.legacy.colors.accent1.default})`

      case 'rate':
        return `linear-gradient(${theme.legacy.colors.accent1.default}, ${theme.legacy.colors.neutral4.default})`

      case 'experts':
        return `linear-gradient(${theme.legacy.colors.neutral4.default}, ${theme.legacy.colors.accent1.default})`

      case 'folder':
        return `linear-gradient(${theme.legacy.colors.accent1.default}, ${theme.legacy.colors.primary1.default})`

      default:
        return theme.legacy.colors.primary1.default
    }
  }};
`

export const StepTitle = styled.div`
  margin-bottom: ${g(2)};
`

export const StepContent = styled.div`
  margin-bottom: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex: 0 0 50%;
  }
`

export const Image = styled(BaseImage).attrs({ options: { width: ng(50) } })`
  display: block;
  width: 100%;
`

export const StepImage = styled.aside`
  margin: ${g(3)} auto 0;
  max-width: ${breakpoints.mobileL};
  width: 100%;

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex: 0 0 50%;
    margin: 0;
  }
`
export const EndImage = styled.div`
  max-width: ${breakpoints.mobileL};
`
export const StepText = styled.p``

export const Step = styled.div`
  position: relative;
  padding-bottom: ${g(7)};
  padding-left: ${g(5)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    padding-left: 0;

    &:nth-child(odd) {
      ${StepContent} {
        order: 1;
        padding-right: ${g(4)};
        text-align: right;
      }

      ${StepImage} {
        order: 2;
        padding-left: ${g(4)};
      }
    }

    &:nth-child(even) {
      ${StepContent} {
        order: 2;
        padding-left: ${g(4)};
      }

      ${StepImage} {
        order: 1;
        padding-right: ${g(4)};
        margin-left: auto;
      }
    }
  }

  &:last-child {
    display: flex;
    flex-direction: column;
    padding-top: ${g(5)};
    padding-left: 0;
    @media screen and (min-width: ${breakpoints.tablet}) {
      margin: auto;
      padding-top: 0;
      max-width: 420px;
    }

    ${StepImage} {
      @media screen and (min-width: ${breakpoints.tablet}) {
        display: none;
      }
    }

    ${StepTitle} {
      @media screen and (min-width: ${breakpoints.tablet}) {
        order: 1;
      }
    }

    ${StepSeparation} {
      @media screen and (min-width: ${breakpoints.tablet}) {
        display: none;
      }
    }

    ${StepSeparationLine} {
      display: none;
    }

    ${StepContent} {
      display: flex;
      flex-direction: column;
      @media screen and (min-width: ${breakpoints.tablet}) {
        padding-left: 0;
      }

      ${EndImage} {
        @media screen and (min-width: ${breakpoints.tablet}) {
          order: 0;
        }
      }
    }

    ${StepText} {
      order: 2;
    }

    ${StepImage} {
      display: none;

      @media screen and (min-width: ${breakpoints.tablet}) {
        display: block;
        order: 0;
        margin: 0 0 ${g(3)};
      }
    }
  }
`
