import SubHeading from '@pretto/bricks/components/typography/SubHeading'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const FIELDS = ['loan', 'rate', 'experts', 'folder', 'negotiation', 'end']

const BASE_SLUG = 'website/page/en-savoir-plus/'

const AboutPage = ({ steps }) => (
  <S.Steps>
    {FIELDS.map(field => {
      const { content, image, title } = steps[field]

      return (
        <S.Step key={field} field={field}>
          <S.StepSeparation>
            <S.StepSeparationDot field={field} />
            <S.StepSeparationLine field={field} />
          </S.StepSeparation>
          <S.StepContent>
            <S.StepTitle>
              <SubHeading type="h3">{title}</SubHeading>
            </S.StepTitle>
            {field === 'end' && (
              <S.EndImage>
                <S.Image alt={title} path={`${BASE_SLUG}${image}`} />
              </S.EndImage>
            )}
            <S.StepText>{content}</S.StepText>
          </S.StepContent>
          {field !== 'end' && (
            <S.StepImage>
              <S.Image alt={title} path={`${BASE_SLUG}${image}`} />
            </S.StepImage>
          )}
        </S.Step>
      )
    })}
  </S.Steps>
)

AboutPage.propTypes = {
  /** Steps describing how it works (shown as a timeline). */
  steps: PropTypes.object.isRequired,
}

export default memo(AboutPage)
